import { useState } from 'react';
import { SharedErrorStateProps } from '@leagueplatform/web-common-components';
import { useGetAppInitDetails } from 'hooks/use-get-app-init-details';
import { useGetHighmarkUserProfile } from 'hooks/use-get-user-profile';
import { useTestConfigFlags } from 'hooks/use-get-test-config-flags.hook';
import { captureUserIneligibleEvent } from 'utils/sentry-helpers';
import {
  AppQualifiers,
  EligibilityInfo,
  PageIneligibility,
} from 'api/get-app-init-details';
import { RegionSelection } from 'components/header-nav/region-selection-context';

export type UsePrivateRoutesContainerReturn = {
  contentConfig: PageIneligibility;
  regionSelection: RegionSelection;
  eligibilityInfo: EligibilityInfo;
  enableLiveChat: boolean;
  qualifiers: AppQualifiers;
  showWalkthrough: boolean;
  onCompleteWalkthrough: () => void;
  showMfa: boolean;
  onCompleteMfa: () => void;
  ebillEligible: boolean | undefined;
  ebillRegistered: boolean | undefined;
  isLoading: boolean;
  errorAppInitDetails: SharedErrorStateProps['error'];
  errorUserProfile: SharedErrorStateProps['error'];
};

export const usePrivateRoutesContainer =
  (): UsePrivateRoutesContainerReturn => {
    const [walkthroughCompleted, setWalkthroughCompleted] = useState(false);
    const [mfaCompleted, setMfaCompleted] = useState(false);
    const { forceAppWalkthrough } = useTestConfigFlags();

    const {
      eligibilityInfo,
      mfaEnrolled,
      contentConfig,
      currentUbk,
      eligibilityOptions,
      enableLiveChat,
      qualifiers,
      ebillEligible,
      ebillRegistered,
      isLoading: isLoadingAppInitdetails,
      error: errorAppInitDetails,
    } = useGetAppInitDetails({
      onSuccess: captureUserIneligibleEvent,
    });

    const {
      data: userData,
      isLoading: isLoadingUserProfile,
      error: errorUserProfile,
    } = useGetHighmarkUserProfile();

    const hasUserAcceptedTerms =
      userData?.userProfile?.memberTermsAndConditions?.dateAccepted || false;

    // If forceAppWalkthrough test flag is set, or if user has not accepted terms
    // then, show walkthrough on initial load.
    const showWalkthrough: boolean =
      (forceAppWalkthrough === 'true' || !hasUserAcceptedTerms) &&
      !walkthroughCompleted;

    const showMfa = !mfaEnrolled && !mfaCompleted;

    const regionSelectionData: RegionSelection = {
      selectedRegion: currentUbk,
      regionOptions: eligibilityOptions,
    };

    return {
      contentConfig,
      eligibilityInfo,
      regionSelection: regionSelectionData,
      enableLiveChat,
      showWalkthrough,
      onCompleteWalkthrough: () => setWalkthroughCompleted(true),
      qualifiers,
      showMfa,
      onCompleteMfa: () => setMfaCompleted(true),
      ebillEligible,
      ebillRegistered,
      isLoading: isLoadingAppInitdetails || isLoadingUserProfile,
      errorAppInitDetails,
      errorUserProfile,
    };
  };
