import React from 'react';
import {
  HeadingText,
  StackItem,
  StackLayout,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { PageHeaderMobile } from 'components/page-header-mobile/page-header-mobile.component';
import {
  ErrorState,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';
import { useLocation } from '@leagueplatform/routing';
import { RichTextRenderer } from 'components/rich-text-renderer/rich-text-renderer.component';
import { useLegalContent } from './use-legal-content.hook';

export const LegalContent = () => {
  const location = useLocation();
  const isMobile = useMediaQuery(queryHelpers.down('tablet'));
  const hasBrowserHistory = Boolean(location.key && location.key !== 'default');
  const showBackButton = hasBrowserHistory && isMobile;
  const { data, isLoading, isError, error } = useLegalContent();

  if (isLoading || isError) {
    return (
      <StackLayout>
        <PageHeaderMobile showBackButton={showBackButton} />
        <StackLayout
          horizontalAlignment="center"
          css={{ minHeight: '50vh', width: '100%', position: 'relative' }}
        >
          {isLoading && <LoadingIndicator />}
          {isError && <ErrorState error={error} />}
        </StackLayout>
      </StackLayout>
    );
  }

  if (isMobile) {
    return (
      <StackLayout>
        <PageHeaderMobile
          headingText={data?.title}
          showBackButton={showBackButton}
        />
        <StackItem css={{ marginTop: '$two' }}>
          <RichTextRenderer>{data?.body}</RichTextRenderer>
        </StackItem>
      </StackLayout>
    );
  }

  return (
    <StackLayout>
      <PageHeaderMobile showBackButton={false} />
      <StackLayout
        spacing="$one"
        css={{
          '@desktop': {
            marginTop: '$half',
          },
        }}
      >
        <HeadingText level="1" size={{ '@initial': 'xxl', '@mobile': 'xl' }}>
          {data?.title}
        </HeadingText>
        <StackItem
          css={{
            marginY: '$one',
            '@desktop': {
              paddingRight: '$two',
            },
          }}
        >
          <RichTextRenderer>{data?.body}</RichTextRenderer>
        </StackItem>
      </StackLayout>
    </StackLayout>
  );
};
