import React, { useEffect } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { Box } from '@leagueplatform/genesis-core';
import { LiveChat } from 'components/live-chat/live-chat.component';
import {
  chatDragId,
  LIVE_CHAT_DROP_ZONES,
} from 'components/chat-dynamic-location/utils/draggable-chat-constants';

type DraggableChatProps = {
  isDragActive: boolean;
  layout: LIVE_CHAT_DROP_ZONES;
  setIsDragActive: (isDragActive: boolean) => void;
};

export const DraggableChatIcon = ({
  isDragActive,
  layout,
  setIsDragActive,
}: DraggableChatProps) => {
  const { active, attributes, listeners, setNodeRef, transform } = useDraggable(
    {
      id: chatDragId,
    },
  );

  // send event data for dragging being active/inactive
  useEffect(() => {
    // the active property is null when inactive, otherwise it has the id of the element being dragged
    const isActive = Boolean(active?.id);
    setIsDragActive(isActive);
  }, [active, setIsDragActive]);

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : '',
  };

  return (
    <Box ref={setNodeRef} style={style}>
      <LiveChat
        cursor={isDragActive ? 'grab' : 'pointer'}
        layout={layout}
        attributes={attributes}
        listeners={listeners}
      />
    </Box>
  );
};
