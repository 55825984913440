import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Box, Button } from '@leagueplatform/genesis-core';
import { ErrorState } from 'components/error/error-state.component';

export const EBillingErrorContent = ({ onClose }: any) => {
  const { formatMessage } = useIntl();
  return (
    <Box>
      <ErrorState
        hideButton
        headingString={formatMessage({ id: 'ERROR_HEADING_GENERIC' })}
        bodyString={formatMessage({ id: 'ERROR_DESCRIPTION_GENERIC' })}
      />
      <Button
        width="fillContainer"
        onClick={onClose}
        css={{ marginTop: '$five' }}
      >
        {formatMessage({ id: 'OKAY' })}
      </Button>
    </Box>
  );
};
