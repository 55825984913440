import * as React from 'react';
import { useLocation } from '@leagueplatform/routing';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';
import { ImpersonationMasonryBanner as ImpersonationBanner } from '@leagueplatform/web-common-components';

/**
 * This is only visible in impersonation mode: Not all widgets on the home page are available to the impersonator. We have to display a message indicating the impersonator may not have a true mirrored image of the member's homepage depending on their permissions.
 */

export const ImpersonationMasonryBanner = () => {
  const location = useLocation();
  const displayImpersonationBanner =
    location?.pathname && location?.pathname === APP_MANAGED_ROUTES.HOME;

  if (!displayImpersonationBanner) return null;
  return <ImpersonationBanner />;
};
