import {
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

/* For all our input sensors, we set activation constraints
 * so the user can interact with the chat icon and open it
 * as the default. Drag functionality only engages once
 * the specified activation logic is met.
 */
export const useActivationSensors = () => {
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 200ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 200,
      tolerance: 5,
    },
  });

  const keyboardSensor = useSensor(KeyboardSensor, {
    keyboardCodes: {
      // Only use the 'Space' bar to activate so 'Enter' can be used to open the chat
      start: ['Space'],
      cancel: ['Escape'],
      end: ['Space'],
    },
  });

  return useSensors(mouseSensor, touchSensor, keyboardSensor);
};
