import { ParagraphText, StatusBanner } from '@leagueplatform/genesis-core';
import { useParams } from '@leagueplatform/routing';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import { useGetClaimsDisclaimerInformation } from 'hooks/use-get-claims-disclaimer-info.hook';

interface ClaimDetailsRouteParams {
  claimId?: string;
}

export function ClaimsDetailDisclaimer() {
  const { claimId = '' } = useParams<ClaimDetailsRouteParams>();

  const {
    data: disclaimerDetails,
    isSuccess,
    isLoading,
  } = useGetClaimsDisclaimerInformation(claimId);

  if (isLoading) {
    return <SkeletonBox height="69px" />;
  }

  // Do not render disclaimer banner if there is no data return
  if (!isSuccess || !disclaimerDetails?.data?.length) {
    return null;
  }

  const { bannerDescription, bannerTitle, bannerType } =
    disclaimerDetails.data[0].attributes;

  return (
    <StatusBanner showIcon status={bannerType} title={bannerTitle}>
      <ParagraphText size="sm" css={{ color: '$onSurfaceTextPrimary' }}>
        {bannerDescription}
      </ParagraphText>
    </StatusBanner>
  );
}
