import { EBillFormData } from 'components/home/e-billing/e-billing-form.component';
import { highmarkFetch } from 'utils/highmark-fetch';

const EBILL_REGISTRATION_URL = '/v1/members/billing/register';

export const postEbillRegistrationForm = async (
  partnerAccessToken: string,
  formData: EBillFormData,
) => {
  const response = await highmarkFetch(
    EBILL_REGISTRATION_URL,
    partnerAccessToken,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    },
  );
  return response.json();
};
