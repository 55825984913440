import React from 'react';
import {
  Button,
  HeadingText,
  Modal,
  ParagraphText,
  StackItem,
  StackLayout,
} from '@leagueplatform/genesis-core';
import {
  INFO_MODAL_TYPES,
  InfoModalPopover,
} from 'components/background-information/info-modal-popover.component';

import { useIntl } from '@leagueplatform/locales';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { useHistory } from '@leagueplatform/routing';
import { BackgroundInformationForm } from 'components/background-information/background-information-form.component';
import { ErrorState } from 'components/error/error-state.component';
import { ERROR_TYPES } from 'hooks/use-error-message/use-error-message';
import {
  useBackgroundInformation,
  useSubmitBackgroundInformation,
} from 'hooks/use-background-information/use-background-information';
import { useDemographicsProfile } from 'hooks/use-demographics-profile';
import { SuccessModalPopover } from './success-modal-popover.component';
import { BackgroundInformationFormData } from './background-information-constants';

export const BackgroundInformationModal = () => {
  const { formatMessage } = useIntl();
  const demographicsProfileQuery = useDemographicsProfile();
  const backgroundInformationQuery = useBackgroundInformation();
  const submitBackgroundInformation = useSubmitBackgroundInformation();
  const history = useHistory();
  const [selectedProfileIndex, setSelectedProfileIndex] = React.useState(0);

  if (
    backgroundInformationQuery.isLoading ||
    demographicsProfileQuery.isLoading
  ) {
    return <LoadingIndicator />;
  }

  if (
    backgroundInformationQuery.isError ||
    demographicsProfileQuery.isError ||
    !demographicsProfileQuery.data ||
    submitBackgroundInformation.isError
  ) {
    return (
      <ErrorState
        error={[
          backgroundInformationQuery.error,
          demographicsProfileQuery.error,
          submitBackgroundInformation.error,
        ]}
        isFullPage
        errorType={ERROR_TYPES.API_ERROR}
      />
    );
  }

  return (
    <Modal.Root
      defaultOpen
      onOpenChange={(open) => {
        if (!open) {
          history.goBack();
        }
      }}
    >
      <Modal.Content
        showCloseButton={false}
        css={{
          '.GDS-modal': {
            width: '80vw',
            minWidth: '425px',
            maxWidth: '950px',
            height: '80vh',
            minHeight: '450px',
            borderRadius: '0px',
          },
        }}
      >
        <Modal.Title>
          {formatMessage({ id: 'BACKGROUND_INFORMATION_TITLE' })}
        </Modal.Title>
        <StackLayout horizontalAlignment="center" css={{ width: '100%' }}>
          <StackLayout
            horizontalAlignment="stretch"
            css={{
              padding: '$oneAndHalf $twoAndHalf',
              minWidth: '420px',
              maxWidth: '700px',
            }}
          >
            <StackItem horizontalAlignment="start" css={{ width: '100%' }}>
              <Modal.Close>
                <Button
                  priority="tertiary"
                  quiet
                  size="medium"
                  icon="interfaceChevronLeft"
                  css={{
                    color: '$onSurfaceIconPrimary',
                    position: 'relative',
                    right: '44px',
                  }}
                >
                  {formatMessage({ id: 'BACKGROUND_INFORMATION_BACK' })}
                </Button>
              </Modal.Close>
              <HeadingText
                level="1"
                size="xxl"
                css={{ paddingY: '$oneAndHalf' }}
              >
                {formatMessage({ id: 'BACKGROUND_INFORMATION_TITLE' })}
              </HeadingText>
            </StackItem>
            <ParagraphText size="sm" css={{ paddingBottom: '$half' }}>
              {formatMessage({ id: 'BACKGROUND_INFORMATION_HEADING' })}
              <InfoModalPopover
                infoModalType={INFO_MODAL_TYPES.BACKGROUND_INFORMATION}
              />
            </ParagraphText>
            <BackgroundInformationForm
              // Forces the react-hook-form to pickup the new initial values after an update.
              key={`background-info-${backgroundInformationQuery.dataUpdatedAt}`}
              selectedProfileIndex={selectedProfileIndex}
              onSelectProfileIndexChange={(index) => {
                setSelectedProfileIndex(index);
              }}
              profiles={demographicsProfileQuery.data}
              initialValues={backgroundInformationQuery.data?.backgroundInfo}
              onSubmit={(
                backgroundInformation: BackgroundInformationFormData,
              ) => {
                const memberId = backgroundInformationQuery.data?.memberId!;
                submitBackgroundInformation.mutate({
                  memberId,
                  backgroundInformation,
                });
              }}
            />
          </StackLayout>
        </StackLayout>
        {submitBackgroundInformation.isSuccess ? (
          <SuccessModalPopover
            isSuccess={submitBackgroundInformation.isSuccess}
          />
        ) : null}
      </Modal.Content>
    </Modal.Root>
  );
};
