import { useStaticAsset } from '@leagueplatform/asset-config';
import { GDSStyleObject } from '@leagueplatform/genesis-core';
import { Disclaimer } from '@leagueplatform/engen-capability-disclaimer';
import { THIRD_PARTY_LEAVE_SITE_EXTERNAL_LINKS } from 'common/constants';
import { HIGHMARK_COMMON_ASSET_KEYS } from 'types/highmark-common.types';
import React from 'react';
import { RegionSelectionModal } from 'components/region-selection/region-selection-modal.component';
import { EBillingRegistrationModal } from 'components/home/e-billing/e-billing-registration-modal.component';

export enum MODAL_PARAMS {
  AMWELL_UNDER_18 = 'amwellunder18',
  LEAVE_SITE_YMCA = 'ymcaexit',
  LEAVE_SITE_LIVONGO = 'livongoexit',
  SWITCH_REGION = 'switchregion',
  PWP_DISCLAIMER = 'pwp-disclaimer',
  EBILL_REGISTRATION = 'ebill',
}

export const VALID_MODAL_PARAMS = [
  MODAL_PARAMS.AMWELL_UNDER_18,
  MODAL_PARAMS.LEAVE_SITE_LIVONGO,
  MODAL_PARAMS.LEAVE_SITE_YMCA,
];

// These modals call APIs that don't work in impersonation mode
export const IMPERSONATION_BLOCK_MODAL_PARAMS = [
  MODAL_PARAMS.PWP_DISCLAIMER,
  MODAL_PARAMS.EBILL_REGISTRATION,
  MODAL_PARAMS.SWITCH_REGION,
];

export const VALID_CUSTOM_MODAL_PARAMS = [MODAL_PARAMS.SWITCH_REGION];

export type QueryModalContent = {
  label?: string;
  image?: string;
  heading?: string;
  body?: string;
  bodyTwo?: string;
  cancelButton?: {
    id: string;
    onClick?: () => void;
  };
  submitButton?: {
    id: string;
    onClick?: () => void;
  };
  styling?: GDSStyleObject;
  customComponent?: React.FunctionComponent<{ onClose: () => void }>;
};

export const useQueryModalContent = (type: MODAL_PARAMS): QueryModalContent => {
  const leaveSiteImage = useStaticAsset(
    HIGHMARK_COMMON_ASSET_KEYS.HIGHMARK_EXIT_WARNING_IMAGE,
  ) as string;

  switch (type) {
    case MODAL_PARAMS.AMWELL_UNDER_18:
      return {
        label: 'AMWELL_DISCLAIMER',
        heading: 'AMWELL_DISCLAIMER_HEADING',
        body: 'AMWELL_DISCLAIMER_BODY_1',
        bodyTwo: 'AMWELL_DISCLAIMER_BODY_2',
      };
    case MODAL_PARAMS.LEAVE_SITE_YMCA:
      return {
        label: 'LEAVE_SITE_DISCLAIMER',
        image: leaveSiteImage,
        heading: 'LEAVE_SITE_DISCLAIMER_HEADING_YMCA',
        body: 'LEAVE_SITE_DISCLAIMER_BODY',
        cancelButton: {
          id: 'CANCEL',
        },
        submitButton: {
          id: 'CONTINUE',
          onClick: () =>
            window.open(
              THIRD_PARTY_LEAVE_SITE_EXTERNAL_LINKS.YMCA,
              '_blank',
              'noreferrer',
            ),
        },
        styling: {
          textAlign: 'center',
        },
      };
    case MODAL_PARAMS.LEAVE_SITE_LIVONGO:
      return {
        label: 'LEAVE_SITE_DISCLAIMER',
        image: leaveSiteImage,
        heading: 'LEAVE_SITE_DISCLAIMER_HEADING_LIVONGO',
        body: 'LEAVE_SITE_DISCLAIMER_BODY',
        cancelButton: {
          id: 'CANCEL',
        },
        submitButton: {
          id: 'CONTINUE',
          onClick: () =>
            window.open(
              THIRD_PARTY_LEAVE_SITE_EXTERNAL_LINKS.LIVONGO,
              '_blank',
              'noreferrer',
            ),
        },
        styling: { textAlign: 'center' },
      };
    case MODAL_PARAMS.SWITCH_REGION:
      return {
        customComponent: RegionSelectionModal,
      };
    case MODAL_PARAMS.PWP_DISCLAIMER:
      return {
        customComponent: Disclaimer,
      };
    case MODAL_PARAMS.EBILL_REGISTRATION:
      return {
        customComponent: EBillingRegistrationModal,
      };
    default:
      return {};
  }
};
