import React from 'react';
import { Button, VisuallyHidden } from '@leagueplatform/genesis-core';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SUB_PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { useIntl } from '@leagueplatform/locales';
import { useLiveChatCta } from '@leagueplatform/live-chat';
import MessagesBubbleTypingIcon from 'assets/icons/messages-bubble-typing.svg';
import { UnreadMessageIndicator } from './live-chat-unread-message-indicator';

export type LiveChatDraggableProps = {
  cursor: 'grab' | 'pointer';
  listeners: any; // dnd-kit doesn't expose the SyntheticListenerMap type
  attributes: { [key: string]: any }; // dnd-kit doesn't expose a type
};

export const floatingElementAnalytics = 'floating element';

export function LiveChatCTA({
  cursor,
  listeners,
  attributes,
}: LiveChatDraggableProps) {
  const { formatMessage } = useIntl();
  const { openChat, unreadMessageCount, liveChatButtonRef } = useLiveChatCta();

  const handleClick = () => {
    openChat();
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.GET_CARE,
      sub_product_area: SUB_PRODUCT_AREA.LIVE_CHAT,
      screen_name: floatingElementAnalytics,
      detail: 'live chat bubble',
    });
  };

  return (
    <UnreadMessageIndicator unreadMessageCount={unreadMessageCount}>
      <Button
        icon={MessagesBubbleTypingIcon}
        hideLabel
        priority="primary"
        onClick={handleClick}
        ref={liveChatButtonRef}
        css={{
          '.GDS-icon': { width: '3rem', height: '3rem', margin: '0.5rem' },
          cursor,
          // These attributes are needed to avoid mobile and browser default actions for haptic feedback
          '-webkit-touch-callout': 'none' /* Safari */,
          '-webkit-user-select': 'none' /* Safari */,
          '-ms-user-select': 'none' /* IE 10+ */,
          'user-select': 'none',
        }}
        aria-live="polite"
        // These are needed to support drag and drop
        {...attributes} // eslint-disable-line react/jsx-props-no-spreading
        {...listeners} // eslint-disable-line react/jsx-props-no-spreading
      >
        <span aria-hidden>{formatMessage({ id: 'LIVE_CHAT' })}</span>
        {unreadMessageCount > 0 ? (
          <VisuallyHidden>
            {formatMessage(
              { id: 'LIVE_CHAT_UNREAD_MESSAGES' },
              { count: unreadMessageCount },
            )}
          </VisuallyHidden>
        ) : (
          formatMessage({ id: 'LIVE_CHAT' })
        )}
      </Button>
    </UnreadMessageIndicator>
  );
}
