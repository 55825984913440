import { LiveChatContainer, useLiveChatCta } from '@leagueplatform/live-chat';
import React from 'react';
import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { LiveChatCTA, LiveChatDraggableProps } from './live-chat-cta.component';

type LiveChatProps = LiveChatDraggableProps & {
  layout: 'bottomLeft' | 'bottomRight';
};

export function LiveChat({
  cursor = 'pointer',
  layout = 'bottomRight',
  listeners,
  attributes,
}: LiveChatProps) {
  const { isVisible } = useLiveChatCta();
  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));

  return (
    <>
      {isVisible ? (
        <LiveChatCTA
          cursor={cursor}
          attributes={attributes}
          listeners={listeners}
        />
      ) : null}
      <LiveChatContainer layout={isMobile ? 'fullscreen' : layout} />
    </>
  );
}
