import { leagueFetch } from '@leagueplatform/league-fetch';
import { GDSStatus } from '@leagueplatform/genesis-core';
import { SENTRY_CONSTANTS } from 'common/constants';

type ClaimsDisclaimerInformationResponseData = {
  id: string;
  type: 'claims-banner';
  attributes: {
    bannerType: GDSStatus;
    bannerTitle: string;
    bannerDescription: string;
  };
};

type GetClaimsDisclaimerInformationResponseData = {
  data: Array<ClaimsDisclaimerInformationResponseData>;
};

const DISCLAIMER_INFO_API = '/v1/claims-banners';

export const getClaimsDisclaimerInformation = async (
  claimId?: string,
): Promise<GetClaimsDisclaimerInformationResponseData> => {
  const response = await leagueFetch(`${DISCLAIMER_INFO_API}/${claimId}`, {
    errorContext: { ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM },
  });
  if (!response?.ok) {
    throw new Error(
      `Error Response Code ${response?.status}: ${await response.text()}`,
    );
  }
  return response.json();
};
