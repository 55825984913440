import {
  APP_MANAGED_ROUTES,
  AuthConfig,
  AuthlessLeagueConfig,
  createLiveChatClient,
  JourneyHeaderBackground,
  LinkHandler,
  LoadingSpinner,
  MASONRY,
  RegionSelectionClaims,
  ClaimsDetailDisclaimer,
  RegionSelectionWallet,
} from '@highmark-web/highmark-common';
import { ENVIRONMENT, LeagueConfig } from '@leagueplatform/core';
/**
 * @ts-ignore is used here due to @leagueplatform/legacy-locales being a
 * temporary measure that is served as a JS utility.
 */
// @ts-ignore
import { legacyStrings } from '@leagueplatform/legacy-locales';
import React from 'react';
import { EN_STRING_OVERRIDES } from '../locales/sdk-string-overrides/en';
import { iELTheme } from '../theme/theme';
import { ASSETS_CONFIG } from './asset-config';
import {
  CLIENT_ID,
  APP_STORE_PROD_URL,
  PLAY_STORE_PROD_URL,
  TENANT_ID,
} from '../common/constants';
import { injectLiveChatQualtricsScript } from '../utils/inject-live-chat-qualtrics-script';

// Content Server Configuration to support multiple domains on prod, so content server url matches the same domain
// TODO: We don't need this after migration is complete: https://everlong.atlassian.net/browse/HMRK-3918
let contentServerUrl = import.meta.env.VITE_CONTENT_SERVER_URL as string;
if (import.meta.env.VITE_ENV === ENVIRONMENT.PRODUCTION) {
  // Gets the base domain e.g. something.highmark.com -> highmark.com
  const baseDomain = window.location.hostname.split('.').slice(-2).join('.');
  contentServerUrl = `https://content.${baseDomain}`;
}

// SDK Initializers that use League's Config API
const AUTHLESS_LEAGUE_CONFIG: AuthlessLeagueConfig = {
  core: {
    clientId: CLIENT_ID,
    tenantId: TENANT_ID,
    // We do not set `auth` yet - it will be added dynamically once we know whether we're in standalone or embedded (mobile auth) mode
    api: {
      wsUrl: import.meta.env.VITE_API_URL as string,
      url: import.meta.env.VITE_REST_API_URL as string,
      legacyRestApi: import.meta.env.VITE_LEGACY_REST_API as string,
    },
    contentUrl: contentServerUrl,
    appEnvironment: import.meta.env.VITE_ENV as ENVIRONMENT,
    analytics: {
      segmentKey: import.meta.env.VITE_SEGMENT,
    },
    customMethods: {
      handleLink: LinkHandler.handleLink,
    },
    routing: {
      isUsingLeagueRouting: true,
    },
    mobileAppUrls: {
      appStoreUrl: APP_STORE_PROD_URL,
      playStoreUrl: PLAY_STORE_PROD_URL,
    },
    i18n: {
      strings: {
        default: { ...legacyStrings.default, ...EN_STRING_OVERRIDES },
        en: { ...legacyStrings.en, ...EN_STRING_OVERRIDES },
      },
    },
    ui: {
      theme: iELTheme,
      components: {
        loading: { element: <LoadingSpinner />, text: false },
        pageHeader: {
          shape: 'line',
          backgroundColor: 'surface.background.secondary',
        },
      },
    },
  },
  __internal: {
    shouldApplyCSSReset: true,
    shouldApplyLegacyGlobalStyles: true,
  },
  assets: {
    overrides: ASSETS_CONFIG,
  },
  aboutMe: {
    showAccountBanner: false,
    showLandingPageBackButton: true,
  },
  careCollections: {
    // Dynamic API-based values are handled in `config/use-dynamic-config.hook.ts`
  },
  careDiscovery: {
    masonryAppId: {
      left: MASONRY.APP_ID_CARE_DISCOVERY_HOME,
    },
  },
  claims: {
    // Dynamic firebase flag values are handled in `config/use-firebase-league-config.hook.ts`
    showTotalCostOverviewAndAdditionalResources: false,
    supportLink: APP_MANAGED_ROUTES.SUPPORT,
    slots: {
      Banner: RegionSelectionClaims,
      ServiceDetailsBanner: ClaimsDetailDisclaimer,
    },
  },
  healthProfile: {
    ratingsBarEnabled: false,
    careSdkEnabled: true,
    chatWithProfessionalEnabled: false,
  },
  healthJourney: {
    tabs: ['activities', 'progress', 'history', 'explore'],
    headerConfig: {
      backgroundImageSrc: JourneyHeaderBackground,
    },
  },
  liveChat: {
    // Dynamic firebase flag values are handled in `config/use-firebase-league-config.hook.ts`
    liveChatClient: createLiveChatClient({
      env: import.meta.env.VITE_ENV,
      onChatEnd() {
        injectLiveChatQualtricsScript();
      },
    }),
  },
  wallet: {
    // Dynamic firebase flag values are handled in `config/use-firebase-league-config.hook.ts`
    showLandingPageHeaderBranding: true,
    showLandingPageClaimsEntry: true,
    slots: {
      Banner: RegionSelectionWallet,
    },
  },
  __care__adjustForCardColorThemeDiscrepancy: true,
} as AuthlessLeagueConfig;

export const getLeagueStaticConfig = (
  authConfig: AuthConfig | undefined,
): LeagueConfig => ({
  ...AUTHLESS_LEAGUE_CONFIG,
  core: {
    ...AUTHLESS_LEAGUE_CONFIG.core,
    auth: authConfig,
  } as LeagueConfig['core'],
});
