import React, { useState } from 'react';
import {
  BodyOne,
  Box,
  HeadingTwo,
  PrimaryButton,
} from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { InputField } from '@leagueplatform/web-common-components';
import { Form, Formik } from 'formik';

export interface EBillFormData {
  billingId: string;
}

const validateForm = (
  values: EBillFormData,
  formatMessage: (descriptor: { id: string }) => string,
) => {
  const errors: any = {};
  if (!values.billingId) {
    errors.billingId = formatMessage({ id: 'THIS_FIELD_IS_REQUIRED' });
  }
  return errors;
};

type EBillingFormProps = {
  onSubmit: (values: EBillFormData) => void;
};

export const EBillingForm = ({ onSubmit }: EBillingFormProps) => {
  const { formatMessage } = useIntl();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <Box>
      <HeadingTwo id="modalTitle" fontSize="heading1">
        {formatMessage({ id: 'E_BILLING_REGISTRATION_TITLE' })}
      </HeadingTwo>
      <BodyOne marginTop="oneAndHalf">
        {formatMessage({ id: 'E_BILLING_REGISTRATION_DESCRIPTION_P1' })}
      </BodyOne>
      <BodyOne marginTop="half">
        {formatMessage({ id: 'E_BILLING_REGISTRATION_DESCRIPTION_P2' })}
      </BodyOne>
      <Formik
        initialValues={{
          billingId: '',
        }}
        validate={(values: EBillFormData) =>
          validateForm(values, formatMessage)
        }
        onSubmit={(values) => {
          setIsSubmitting(true);
          onSubmit(values);
        }}
      >
        {({ errors }) => (
          <Form>
            <Box marginTop="twoAndHalf">
              {/* @ts-ignore */}
              <InputField
                required
                name="billingId"
                inputType="text"
                label={formatMessage({ id: 'BILLING_ID' })}
                error={errors?.billingId}
              />
            </Box>
            <PrimaryButton
              width={1}
              type="submit"
              marginTop="five"
              disabled={isSubmitting}
            >
              {formatMessage({ id: 'SUBMIT' })}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
