import { useMutation, useQueryClient } from 'react-query';
import { postEbillRegistrationForm } from 'api/post-ebill-registration-form';
import { useGetIdentityPartnerToken } from 'hooks/use-get-identity-partner-token';
import { DEFAULT_PARTNER_ID, QUERY_KEYS } from 'common/constants';
import { EBillFormData } from './e-billing-form.component';

export const useSubmitBillingIdForRegistration = () => {
  const { data: identityTokenData } =
    useGetIdentityPartnerToken(DEFAULT_PARTNER_ID);

  const submitBillingId = useMutation((postData: EBillFormData) =>
    postEbillRegistrationForm(identityTokenData, postData),
  );

  const queryClient = useQueryClient();

  return async (formData: EBillFormData) => {
    try {
      const response = await submitBillingId.mutateAsync(formData);
      const isSuccess = response.response === 'success';
      if (isSuccess) {
        // invalidate the user's registration eligibility data once it has been updated (via successful registration)
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.EBILL_ELIGIBILITY_KEY],
        });
      }
      return { isSuccess };
    } catch (err) {
      return { isSuccess: false };
    }
  };
};
