import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { HIGHMARK_CONNECTION_SESSION_STORAGE_KEY } from 'common/sso-constants';

export const handleLoginRedirect = () => {
  let connection;

  // check if window is defined to avoid SSR errors
  if (typeof window !== 'undefined') {
    connection = new URLSearchParams(window.location.search).get('connection');
  }

  if (connection) {
    sessionStorage.setItem(HIGHMARK_CONNECTION_SESSION_STORAGE_KEY, connection);
    StandaloneAuth.client.loginWithRedirect({ connection });
  } else {
    sessionStorage.removeItem(HIGHMARK_CONNECTION_SESSION_STORAGE_KEY);
    StandaloneAuth.client.loginWithRedirect();
  }
};
