import React, { useEffect } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { useGetIsImpersonator } from '@leagueplatform/auth-utils';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';
import { HIGHMARK_CONNECTION_SESSION_STORAGE_KEY } from 'common/sso-constants';

export const AuthSignOut = () => {
  const isImpersonation = useGetIsImpersonator();

  useEffect(() => {
    if (isImpersonation !== null) {
      StandaloneAuth.client.logout({
        // federated logout causes issues when logging out of impersonation, so we disable it
        federated: !isImpersonation,
        returnTo: window.location.origin,
      });
      sessionStorage.removeItem(HIGHMARK_CONNECTION_SESSION_STORAGE_KEY);
    }
  }, [isImpersonation]);

  return <LoadingSpinner />;
};
