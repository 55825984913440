import React, { useState } from 'react';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import { Box } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { LinkHandler } from 'utils/link-handler';
import { getSsoUrl } from 'utils/sso-url-helpers';
import { SSO_URL_NAMES } from 'common/sso-constants';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';
import { ModalWrapper } from 'components/modal-wrapper/modal-wrapper.component';
import { EBillFormData, EBillingForm } from './e-billing-form.component';
import { EBillingErrorContent } from './e-billing-error.component';
import { useSubmitBillingIdForRegistration } from './use-submit-billing-id-for-registration.hook';

type EBillingRegistrationModalProps = {
  onClose: () => void;
};

export const EBillingRegistrationModal = ({
  onClose,
}: EBillingRegistrationModalProps) => {
  const { formatMessage } = useIntl();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showErrorState, setShowErrorState] = useState<boolean>(false);
  const submitBillingIdForRegistration = useSubmitBillingIdForRegistration();

  const onSubmit = async (values: EBillFormData) => {
    setIsLoading(true);
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      screen_name: 'Home page',
      sub_product_area: 'E-Bill Registration Modal',
      detail: 'E-Bill Submit Registration',
    });
    const { isSuccess } = await submitBillingIdForRegistration(values);
    if (isSuccess) {
      LinkHandler.handleLink(getSsoUrl(SSO_URL_NAMES.E_BILL));
      onClose();
    } else {
      setIsLoading(false);
      setShowErrorState(true);
    }
  };

  return (
    <ModalWrapper
      onClose={onClose}
      modalLabel={formatMessage({ id: 'E_BILLING_MODAL_LABEL' })}
    >
      <Box>
        {isLoading && <LoadingSpinner />}
        {!showErrorState ? (
          <EBillingForm onSubmit={onSubmit} />
        ) : (
          <EBillingErrorContent onClose={onClose} />
        )}
      </Box>
    </ModalWrapper>
  );
};
