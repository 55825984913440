import * as React from 'react';
import { useContext, useState } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SUB_PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { useIntl } from '@leagueplatform/locales';
import {
  BottomLeftDropZone,
  BottomRightDropZone,
} from 'components/chat-dynamic-location/drop/chat-drop-zones.component';
import { useActivationSensors } from 'components/chat-dynamic-location/utils/use-activation-sensors.hook';
import { LiveChatLayoutContext } from 'components/chat-dynamic-location/utils/live-chat-layout-context.component';
import { DraggableChatIcon } from 'components/chat-dynamic-location/drag/draggable-chat-icon.component';
import { floatingElementAnalytics } from 'components/live-chat/live-chat-cta.component';
import {
  leftDropId,
  LIVE_CHAT_DROP_ZONES,
  rightDropId,
} from 'components/chat-dynamic-location/utils/draggable-chat-constants';

export const DraggableLiveChat = () => {
  const { liveChatLayout, setLiveChatLayout } = useContext(
    LiveChatLayoutContext,
  );
  const { formatMessage } = useIntl();
  const accessibilityData = {
    screenReaderInstructions: {
      draggable: formatMessage({
        id: 'LIVE_CHAT_SCREEN_READER_INSTRUCTIONS',
      }),
    },
  };

  const [isDragActive, setIsDragActive] = useState(false);
  const sensors = useActivationSensors();

  // Set the chat parent container to the default (right)
  const [chatParentId, setChatParentId] = useState(
    liveChatLayout === LIVE_CHAT_DROP_ZONES.BOTTOM_RIGHT
      ? rightDropId
      : leftDropId,
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { over } = event;

    // If the item is dropped over a container, set it as
    // the parent and update the layout context
    if (over) {
      const { id } = over;
      setChatParentId(id as string);
      setLiveChatLayout(
        id === leftDropId
          ? LIVE_CHAT_DROP_ZONES.BOTTOM_LEFT
          : LIVE_CHAT_DROP_ZONES.BOTTOM_RIGHT,
      );
      // We only want to fire analytics when the user successfully completes a drag event
      trackAnalyticsEvent(EVENT_NAME.EVENT_FIRED, {
        product_area: PRODUCT_AREA.GET_CARE,
        sub_product_area: SUB_PRODUCT_AREA.LIVE_CHAT,
        screen_name: floatingElementAnalytics,
        detail: 'chat bubble drop zone changed',
      });
    } else {
      // otherwise reset the parent and layout to the default (right)
      setChatParentId(rightDropId);
      setLiveChatLayout(LIVE_CHAT_DROP_ZONES.BOTTOM_RIGHT);
    }

    setIsDragActive(false);
  };

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      sensors={sensors}
      accessibility={accessibilityData}
    >
      {/* Conditional Bottom Left Drop Zone
       * When the chat parent is the right zone, we want the left zone higher in the HTML structure
       * due to how z-index does relative stacking
       */}
      {chatParentId === rightDropId && (
        <BottomLeftDropZone isActive={isDragActive} />
      )}

      {/* Bottom Right Drop Zone */}
      <BottomRightDropZone isActive={isDragActive}>
        {chatParentId === rightDropId && (
          <DraggableChatIcon
            isDragActive={isDragActive}
            layout={liveChatLayout}
            setIsDragActive={(isActive: boolean) => setIsDragActive(isActive)}
          />
        )}
      </BottomRightDropZone>

      {/* Conditional Bottom Left Drop Zone
       * When the chat parent is the left zone, we want the left zone lower in the HTML structure
       * due to how z-index does relative stacking
       */}
      {chatParentId === leftDropId && (
        <BottomLeftDropZone isActive={isDragActive}>
          <DraggableChatIcon
            isDragActive={isDragActive}
            layout={liveChatLayout}
            setIsDragActive={(isActive: boolean) => setIsDragActive(isActive)}
          />
        </BottomLeftDropZone>
      )}
    </DndContext>
  );
};
