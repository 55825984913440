import React, { useEffect } from 'react';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';
import { handleLoginRedirect } from 'utils/handle-login-redirect.util';

export const AuthSignIn = () => {
  useEffect(() => {
    handleLoginRedirect();
  }, []);

  return <LoadingSpinner />;
};
